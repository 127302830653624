// import underline from '../../../images/small-title-shape.svg';
import './PageTitle.css';

const PageTitle = ({ title, subtitle }: { title: string; subtitle: string }) => {
    return (
        <div className="page-title-area">
            <div className="page-title">
                <h2 className="title">
                    {title}
                    {/* <span>
                        <img src={underline} alt="Underline" />
                    </span> */}
                </h2>
                <p>{subtitle}</p>
            </div>
        </div>
    );
};

export default PageTitle;
