import goalImg from '../../../images/goal.png';
// import underline from '../../../images/small-title-shape.svg';

const OurGoal = () => {
    return (
        <div className="goal-section">
            <div className="container goal-area">
                <div className="goal-left">
                    <p>Our Goal</p>
                    <h2 className="title">
                        We Create Innovative Tools to Empower Around the World
                        {/* <span>
                            <img src={underline} alt="Underline" />
                        </span> */}
                    </h2>
                    <p className="goal-content">
                        Our main focus is to deliver high-quality and scalable custom PHP
                        applications using WordPress. We focus our diligent coding abilities and
                        integrate the latest development trends, and best practices available in the
                        industry to create plugins that help individuals or companies with
                        eCommerce, Project Management, and Enterprise Solutions. We love simplicity
                        and intuitiveness, so all of our plugins are user-centric and designed to be
                        Front End friendly.
                    </p>
                </div>
                <div className="goal-right">
                    <img src={goalImg} alt="image" />
                </div>
            </div>
        </div>
    );
};

export default OurGoal;
