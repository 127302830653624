import counterIcon1 from '../../../images/counter-icon-1.svg';
import counterIcon2 from '../../../images/counter-icon-2.svg';
import counterIcon3 from '../../../images/counter-icon-3.svg';
import counterIcon4 from '../../../images/counter-icon-4.svg';
import glance1 from '../../../images/glance1.png';
import glance2 from '../../../images/glance2.png';
import glance3 from '../../../images/glance3.png';
import glance4 from '../../../images/glance4.png';
// import underline from '../../../images/small-title-shape.svg';

const SoftcentAtaGlance = () => {
    return (
        <div className="section-margin">
            <div className="container">
                <div className="softcent-glance-area">
                    <h2 className="title">
                        Softcent at A Glance
                        {/* <span>
                            <img src={underline} alt="Underline" />
                        </span> */}
                    </h2>
                    <p>
                        Our mission is to assist small , medium, and large businesses to upscale
                        effectiveness, productivity, and profitability. Our vision is to assure our
                        business partners perform the best to give hundred percent clients
                        satisfaction. Our goal is to build the future today for the betterment of
                        humanity with collaboration.
                    </p>
                </div>
                <div className="counter-area">
                    <div className="counter-box">
                        <div className="counter-icon">
                            <img src={counterIcon1} alt="icon" />
                        </div>
                        <div className="counter">
                            <h3>20+</h3>
                            <p>Team Members</p>
                        </div>
                    </div>
                    <div className="counter-box">
                        <div className="counter-icon">
                            <img src={counterIcon2} alt="icon" />
                        </div>
                        <div className="counter">
                            <h3>300+</h3>
                            <p>Happy Customers</p>
                        </div>
                    </div>
                    <div className="counter-box">
                        <div className="counter-icon">
                            <img src={counterIcon3} alt="icon" />
                        </div>
                        <div className="counter">
                            <h3>100</h3>
                            <p>World Wide</p>
                        </div>
                    </div>
                    <div className="counter-box">
                        <div className="counter-icon">
                            <img src={counterIcon4} alt="icon" />
                        </div>
                        <div className="counter">
                            <h3>06</h3>
                            <p>Our Products</p>
                        </div>
                    </div>
                </div>
                <div className="glance-people">
                    <div>
                        <img src={glance1} alt="Glance" />
                    </div>
                    <div className="glance-image">
                        <img src={glance2} alt="Glance" />
                    </div>
                    <div>
                        <img src={glance3} alt="Glance" />
                    </div>
                    <div className="glance-image">
                        <img src={glance4} alt="Glance" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SoftcentAtaGlance;
