import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import person from '../../../images/person.png';
import quote1 from '../../../images/quote1.png';
import quote2 from '../../../images/quote2.png';
// import underline from '../../../images/small-title-shape.svg';
import testimonial from '../../../images/testimonial.png';

const Testimonial = () => {
    return (
        <div className="container section-margin">
            <h2 className="title">
                Talk About Us!
                {/* <span>
                    <img src={underline} alt="Underline" />
                </span> */}
            </h2>

            <div className="quote-area">
                <Swiper
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 1,
                        },
                    }}
                    modules={[Navigation, Autoplay]}
                    className="quote-left"
                    spaceBetween={30}
                    slidesPerView={3}
                    navigation={false}
                    autoplay
                >
                    <SwiperSlide>
                        <div>
                            <p>
                                <span className="left-quote-icon">
                                    <img src={quote1} alt="image" />
                                </span>
                                We had a bit of a slow and bumpy start, but after changing the
                                designer on their side everything went in a very good direction. The
                                designers were talented and easy to work with.
                                <span className="right-quote-icon">
                                    <img src={quote2} alt="image" />
                                </span>
                            </p>
                        </div>
                        <div className="reviewer">
                            <div>
                                <img src={person} alt="image" />
                            </div>
                            <div>
                                <h4>Shuvo Roy</h4>
                                <label>Softcent Co-Founder</label>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <p>
                                <span className="left-quote-icon">
                                    <img src={quote1} alt="" />
                                </span>
                                We had a bit of a slow and bumpy start, but after changing the
                                designer on their side everything went in a very good direction. The
                                designers were talented and easy to work with.
                                <span className="right-quote-icon">
                                    <img src={quote2} alt="" />
                                </span>
                            </p>
                        </div>
                        <div className="reviewer">
                            <div>
                                <img src={person} alt="" />
                            </div>
                            <div>
                                <h4>Jane Doe</h4>
                                <label>CTO</label>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="quote-right">
                    <img src={testimonial} alt="image" />
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
