import OurCustomers from '../Home/OurCustomers/OurCustomers';
import './About.css';
import AboutTop from './AboutTop/AboutTop';
import OurGoal from './OurGoal/OurGoal';
import OurStory from './OurStory/OurStory';
import SoftcentAtaGlance from './SoftcentAtaGlance/SoftcentAtaGlance';
import Team from './Team/Team';

const About = () => {
    return (
        <div id="about">
            <AboutTop />
            <OurGoal />
            <OurStory />
            <Team />
            <SoftcentAtaGlance />
            <OurCustomers />
        </div>
    );
};

export default About;
