import { Image, Shimmer } from 'react-shimmer';
import team1 from '../../../images/team1.png';
import team2 from '../../../images/team2.png';
import team3 from '../../../images/team3.png';
import team4 from '../../../images/team4.png';
import team5 from '../../../images/team5.png';
import team6 from '../../../images/team6.png';
import team7 from '../../../images/team7.png';
import team8 from '../../../images/team8.png';
import team9 from '../../../images/team9.png';
// import underline from '../../../images/small-title-shape.svg';
import './Team.css';
const Team = () => {
    return (
        <div className="section-margin">
            <div className="container">
                <div className="team-title">
                    <p>Team</p>
                    <h2 className="title">
                        Meet The Team
                        {/* <span>
                            <img src={underline} alt="Underline" />
                        </span> */}
                    </h2>
                </div>
                <div className="team-members">
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team1}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team1} alt="Member" /> */}
                        <h2 className="member-title">Howlader Sabuj</h2>
                        <h3 className="member-subtitle">Chief Executive Officer</h3>
                    </div>
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team9}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team1} alt="Member" /> */}
                        <h2 className="member-title">Daniel Matikainen</h2>
                        <h3 className="member-subtitle">Chief Operating Officer</h3>
                    </div>
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team2}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team2} alt="Member" /> */}
                        <h2 className="member-title">Maruf Hossen</h2>
                        <h3 className="member-subtitle">Chief Technology Officer</h3>
                    </div>
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team3}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team3} alt="Member" /> */}
                        <h2 className="member-title">Masud Rana</h2>
                        <h3 className="member-subtitle">Chief Financial Officer</h3>
                    </div>
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team4}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team4} alt="Member" /> */}
                        <h2 className="member-title">Sumon Biswas</h2>
                        <h3 className="member-subtitle">Full Stack Developer</h3>
                    </div>
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team5}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team5} alt="Member" /> */}
                        <h2 className="member-title">Nasir Siraj</h2>
                        <h3 className="member-subtitle">App Developer</h3>
                    </div>
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team6}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team6} alt="Member" /> */}
                        <h2 className="member-title">Muhtasim Fahim</h2>
                        <h3 className="member-subtitle">Blockchain Developer</h3>
                    </div>
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team7}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team7} alt="Member" /> */}
                        <h2 className="member-title">Ataur Rahman</h2>
                        <h3 className="member-subtitle">Frontend Developer</h3>
                    </div>
                    <div className="member">
                        <Image
                            className="image-shimmer"
                            src={team8}
                            fallback={
                                <Shimmer className="image-shimmer" width={272} height={272} />
                            }
                        />
                        {/* <img src={team8} alt="Member" /> */}
                        <h2 className="member-title">Shuvo Roy</h2>
                        <h3 className="member-subtitle">UI & UX Designer </h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;
