import bannerimage from '../../../images/about.jpg';
import PageTitle from '../../Shared/PageTitle/PageTitle';

const AboutTop = () => {
    const title: string = 'Turn your ideas into reality with softcent team!';
    const subtitle: string =
        'With our passion and with the help of our mentors, we started Softcent to remove the barriers that impact Software Development and client satisfication. We are here to help small, medium, and large businesses to upscale effectiveness, productivity, and profitability.';

    return (
        <div className="about-top-section section-margin">
            <div className="container">
                <div className="about-area">
                    <PageTitle title={title} subtitle={subtitle} />
                </div>

                <div className="about-image">
                    <img src={bannerimage} alt="" />
                </div>
            </div>
        </div>
    );
};

export default AboutTop;
