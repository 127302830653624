import Databases from '../Databases/Databases';
import DevTechnology from '../DevTechnology/DevTechnology';
import Frameworks from '../Frameworks/Frameworks';
import Hero from '../Hero/Hero';
import OurCustomers from '../OurCustomers/OurCustomers';
import OurProducts from '../OurProducts/OurProducts';
import Portfolio from '../Portfolio/Portfolio';
// import PricingPlan from '../PricingPlan/PricingPlan';
// import Testimonial from '../Testimonial/Testimonial';
import WhatWeDo from '../WhatWeDo/WhatWeDo';
import Workflow from '../Workflow/Workflow';

const Home = () => {
    return (
        <>
            <Hero />
            <OurCustomers />
            <div id="services">
                <WhatWeDo />
            </div>
            <DevTechnology />
            <Frameworks />
            <Databases />
            <div id="portfolio">
                <OurProducts />
                <Portfolio />
            </div>
            {/* <PricingPlan />
            <Testimonial /> */}

            <Workflow />
        </>
    );
};

export default Home;
