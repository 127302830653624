import OurCustomers from '../Home/OurCustomers/OurCustomers';
import Testimonial from '../Home/Testimonial/Testimonial';
import './Work.css';
import WorkTop from './WorkTop/WorkTop';

const Work = () => {
    return (
        <>
            <WorkTop />
            <Testimonial />
            <OurCustomers />
        </>
    );
};

export default Work;
