import { useState } from 'react';
import PageTitle from '../Shared/PageTitle/PageTitle';
import './RefundPolicy.css';

const RefundPolicy = () => {
    // Defined interface
    interface User {
        name: string;
        email: string;
        phone: string;
        message: string;
        budget: string;
    }

    // Defined state
    const [userData, setUserData] = useState<User>({
        name: '',
        email: '',
        phone: '',
        message: '',
        budget: '',
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Onchange event handler
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    // Form handler
    const formHandler = (e: any) => {
        e.preventDefault();
        passUserData(userData);
    };

    // Custom function
    const passUserData = async (data: User) => {
        try {
            setLoading(true);
            const response = await fetch(`https://softcent-website.herokuapp.com/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            setLoading(false);

            if (response.ok) {
                console.log(responseData.data);
                // Set Message
                setSuccessMessage('Your query has been submitted!');

                // Window scroll top
                window.scrollTo(0, 0);

                // Input field empty
                setUserData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    budget: '',
                });
            } else {
                console.log('Error');
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    /* Passed page-title data */
    const title: string = ``;
    const subtitle: string = '';

    return (
        <div id="contact">
            <div className="contact-section">
                <div className="container">
                    <PageTitle title={title} subtitle={subtitle} />
                    <div>
                        <h1>Refund Policy:</h1>
                        <p>
                            Once you buy a package whether it’s monthly or yearly or a custom
                            contract with us for the development of your products or services if our
                            team could not provide you with services based on your requirements then
                            we will refund your payment within the next 7 working days. Simply
                            cancel the contract and money will be in your account within the next 7
                            working days after canceling the contract. If you have any questions or
                            want to know more about the refund policy then please contact our
                            support at howlader.sabuj@softcent.eu
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RefundPolicy;
