import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import logo from '../../../images/logo.png';

import './Header.css';

const Header = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [isDesktop, setIsDesktop] = useState<boolean>();

    function switch_menu() {
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        window.innerWidth > 767 ? setIsDesktop(true) : setIsDesktop(false);
    }, []);

    window.addEventListener('resize', () => {
        window.innerWidth > 767 ? setIsDesktop(true) : setIsDesktop(false);
    });

    return (
        <header>
            <div className="nav_bar">
                {!isDesktop && (
                    <div className="mobile-menu">
                        <div className="logo">
                            <NavLink to="/">
                                <img src={logo} alt="Softcent" />
                            </NavLink>
                        </div>
                        <div className="menu_switch">
                            <i
                                onClick={switch_menu}
                                className={`${showMenu ? 'fas fa-times' : 'fas fa-bars'}`}
                            />
                        </div>
                    </div>
                )}
                <div className={`container nav_container ${showMenu ? 'menu_active' : null}`}>
                    <div className="logo">
                        <NavHashLink smooth to="/#">
                            <img src={logo} alt="Softcent" />
                        </NavHashLink>
                    </div>
                    <ul className="menu_items">
                        <li>
                            <NavHashLink smooth to="/#services" onClick={switch_menu}>
                                Services
                            </NavHashLink>
                        </li>
                        <li>
                            <NavHashLink smooth to="/#portfolio" onClick={switch_menu}>
                                Portfolio
                            </NavHashLink>
                        </li>
                        <li>
                            <NavHashLink smooth to="/about#about" onClick={switch_menu}>
                                Company
                            </NavHashLink>
                        </li>
                        <li>
                            <NavHashLink
                                smooth
                                to="/contact#contact"
                                onClick={switch_menu}
                                className="btnSkyBlue"
                            >
                                Get in Touch
                            </NavHashLink>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;
