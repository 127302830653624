import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    // eslint-disable-next-line prettier/prettier
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
// import underline from '../../../images/small-title-shape.svg';
import workflow from '../../../images/workflow.png';

const Workflow = () => {
    return (
        <div className="section-margin">
            <div className="container">
                <div className="workflow-flow-title">
                    <h2 className="title">Workflow</h2>
                    <p>
                        Software development and your creative needs, let’s build it together
                        whether you are in the idea stage with a big dream to build the next unicorn
                        or an existing business to increase revenue. Not any more wasteful
                        consultants, no extensive enlistment strategies. No agreements. Get it done
                        based on your requirements.
                    </p>
                </div>
                <div className="accordion-area">
                    <Accordion className="accordion-left" preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>Defining Goal</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Project analysis, defining project requirements and objectives
                                    based on clients needs, cost and effort estimation, creating
                                    value and process planning.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>Product Design</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Designing User Experience (UX) and User Interface (UI), and
                                    creating a strong brand identity.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>Development</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Developing projects from the beginning to the end, innovating,
                                    application consolidation and enhancing existing projects,
                                    application consolidation.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>Testing</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Testing codes, debugging and quality assurance, performance,
                                    functional and security testing, usability evaluation.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>Product Release</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Delivering the final product from the staging to the production
                                    environment and making it available for the end-users
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>Maintenance Support</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Monitoring and maintaining customers’ systems and applications,
                                    providing all technical support and troubleshooting.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>

                    <div className="accordion-right">
                        <img src={workflow} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Workflow;
