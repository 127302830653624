import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import client1 from '../../../images/client1.svg';
import client2 from '../../../images/client2.svg';
import client3 from '../../../images/client3.png';
import client5 from '../../../images/client5.png';
import client6 from '../../../images/client6.png';
import client7 from '../../../images/client7.png';
// import underline from '../../../images/small-title-shape.svg';

const OurCustomers = () => {
    return (
        <div className="section-margin">
            <div className="container">
                <h2 className="title">
                    Our Clients
                    {/* <span>
                        <img src={underline} alt="Underline" />
                    </span> */}
                </h2>
                <Swiper
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                    }}
                    modules={[Navigation, Autoplay]}
                    className="brand-logo"
                    spaceBetween={30}
                    slidesPerView={3}
                    navigation={false}
                    autoplay
                >
                    <SwiperSlide>
                        <img src={client1} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client2} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client3} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client5} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client6} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={client7} alt="" />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
};

export default OurCustomers;
