import { FaFacebook, FaLinkedinIn } from 'react-icons/fa';
import { NavHashLink } from 'react-router-hash-link';
import logo from '../../../images/logo.svg';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer-partner">
                    <h3>
                        Let's us listen to you <br />
                    </h3>
                    <div>
                        {/* <Link to="/contact#contact" className="btnFooter"> */}
                        <NavHashLink className="btnFooter" smooth to="/contact#contact">
                            Start Project
                        </NavHashLink>
                        {/* </Link> */}
                    </div>
                </div>
                <div className="footer-content">
                    <div className="footer-left">
                        <div>
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="footer-info">
                            <p>Estonian registry code: 16356905</p>
                            <p>Address: Karu 17, 10120 Tallinn, Estonia</p>
                            <p>Email: howlader.sabuj@softcent.eu</p>
                            <p>Phone: +372 57836500</p>
                        </div>
                        <div className="social-icon">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/softcent">
                                        <FaFacebook />
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.linkedin.com/company/softcent-software-development">
                                        <FaLinkedinIn />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-nav">
                        <h4>Services</h4>
                        <ul>
                            <li>
                                <NavHashLink smooth to="/#services">
                                    Web Development
                                </NavHashLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/#services">
                                    Mobile App Development
                                </NavHashLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/#services">
                                    Blockchain Development
                                </NavHashLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/#services">
                                    Digital Marketing
                                </NavHashLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/#services">
                                    Graphics & Visual
                                </NavHashLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/#services">
                                    Web & app
                                </NavHashLink>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-nav">
                        <h4>Company</h4>
                        <ul>
                            <li>
                                <NavHashLink smooth to="/#portfolio">
                                    Portfolio
                                </NavHashLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/#services">
                                    Services
                                </NavHashLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/about#about">
                                    Company
                                </NavHashLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/contact#contact" className="btnSkyBlue">
                                    Contact Us
                                </NavHashLink>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-nav">
                        <h4>Terms & Policy</h4>
                        <ul>
                            <li>
                                <a href="/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/cookie-policy">Cookie Policy</a>
                            </li>
                            <li>
                                <a href="/refund-policy">Refund Policy</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="copyright">
                    <p>&copy; 2022, Softcent EU</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
