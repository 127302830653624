import { useState } from 'react';
import PageTitle from '../Shared/PageTitle/PageTitle';
import './Contact.css';

const Contact = () => {
    // Defined interface
    interface User {
        name: string;
        email: string;
        phone: string;
        message: string;
        budget: string;
    }

    // Defined state
    const [userData, setUserData] = useState<User>({
        name: '',
        email: '',
        phone: '',
        message: '',
        budget: '',
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Onchange event handler
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    // Form handler
    const formHandler = (e: any) => {
        e.preventDefault();
        passUserData(userData);
    };

    // Custom function
    const passUserData = async (data: User) => {
        try {
            setLoading(true);
            const response = await fetch(`https://softcent-website.herokuapp.com/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            setLoading(false);

            if (response.ok) {
                console.log(responseData.data);
                // Set Message
                setSuccessMessage('Your query has been submitted!');

                // Window scroll top
                window.scrollTo(0, 0);

                // Input field empty
                setUserData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    budget: '',
                });
            } else {
                console.log('Error');
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    /* Passed page-title data */
    const title: string = `This is the first step of our collaboration with you; Please provide your details here`;
    const subtitle: string = '';

    return (
        <div id="contact">
            <div className="contact-section">
                <div className="container">
                    <PageTitle title={title} subtitle={subtitle} />

                    {loading && <h1>Loading...</h1>}

                    {!loading && (
                        <form onSubmit={formHandler} className="contact-form">
                            {successMessage && <p className="successMsg">{successMessage}</p>}
                            <div>
                                <label htmlFor="Name">Your full name</label>
                                <input
                                    onChange={handleChange}
                                    type="text"
                                    name="name"
                                    value={userData.name}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="Email">Email address</label>
                                <input
                                    onChange={handleChange}
                                    type="text"
                                    name="email"
                                    value={userData.email}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="Phone">Phone Number</label>
                                <input
                                    onChange={handleChange}
                                    type="text"
                                    name="phone"
                                    value={userData.phone}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="Message">
                                    Please describe your project in a few lines
                                </label>
                                {/* <textarea
                                onChange={handleChange}
                                name="message"
                                id=""
                                cols="30"
                                rows="4"
                            >
                                {userData.message}
                            </textarea> */}
                                <input
                                    onChange={handleChange}
                                    type="text"
                                    name="message"
                                    value={userData.message}
                                />
                            </div>
                            <div>
                                <label htmlFor="Budget">Your Budget(Optional)</label>
                                <input
                                    onChange={handleChange}
                                    type="text"
                                    name="budget"
                                    value={userData.budget}
                                    required
                                />
                            </div>
                            <div>
                                <button type="submit" className="form-button">
                                    Send
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Contact;
