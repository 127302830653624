const Hero = () => {
    return (
        <div className="section-margin">
            <div className="container">
                <div className="hero-section">
                    <div className="hero-left">
                        <h2>Customized software development services </h2>
                        <h2>For businesses and startup.</h2>
                        {/* <h2>Strive to give more for less</h2> */}
                        {/* <p>Customized software development services for businesses and startup.</p> */}
                    </div>
                    {/* <div className="hero-right"></div> */}
                </div>
                {/* <div className="video-section">
                    <img src={heroImg} alt="" />
                </div> */}
            </div>
        </div>
    );
};

export default Hero;
