// import underline from '../../../images/small-title-shape.svg';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Databases.css';

import mongodbLogo from '../../../images/stacks/mongodb.png';
import mysqlLogo from '../../../images/stacks/mysql.png';
import pgLogo from '../../../images/stacks/pg.png';

const DevTechnology = () => {
    return (
        <div className="section-margin">
            <div className="container">
                <h2 className="title dev-technology-title">
                    Databases
                    {/* <span>
                    <img src={underline} alt="Underline" />
                </span> */}
                </h2>
                <div className="stack_section">
                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                        }}
                        modules={[Navigation, Autoplay]}
                        className="dev-logo"
                        spaceBetween={30}
                        slidesPerView={3}
                        navigation={false}
                        autoplay
                    >
                        <SwiperSlide>
                            <img src={pgLogo} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={mysqlLogo} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={mongodbLogo} alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default DevTechnology;
