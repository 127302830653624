import icon3 from '../../../images/icon3.png';
// import underline from '../../../images/small-title-shape.svg';

const WhatWeDo = () => {
    return (
        <>
            <div className="section-margin">
                <div className="container">
                    <h2 className="title what-we-do-title">
                        Grow Your Business
                        {/* <span>
                        <img src={underline} alt="Underline" />
                    </span> */}
                    </h2>
                    <div className="service-area">
                        <div className="development">
                            <div className="icon-title">
                                <div className="icon">
                                    <img src={icon3} alt="" />
                                </div>
                                <h2>Web Development</h2>
                            </div>
                            <div className="services">
                                <ul>
                                    <li>Static web application</li>
                                    <li>Dynamic web application</li>
                                    <li>E-Commerce web application</li>
                                    <li>Single-page web application</li>
                                    <li>CMS web application</li>
                                    <li>Animated web applications</li>
                                    <li>Rich Internet web applications</li>
                                </ul>
                            </div>
                        </div>
                        <div className="development">
                            <div className="icon-title">
                                <div className="icon">
                                    <img src={icon3} alt="" />
                                </div>
                                <h2>Mobile App Development</h2>
                            </div>
                            <div className="services">
                                <ul>
                                    <li>Educational apps</li>
                                    <li>Lifestyle apps</li>
                                    <li>Social media apps</li>
                                    <li>Productivity apps</li>
                                    <li>Entertainment apps</li>
                                    <li>Game apps</li>
                                    <li>Fintech Apps</li>
                                </ul>
                            </div>
                        </div>
                        <div className="development">
                            <div className="icon-title">
                                <div className="icon">
                                    <img src={icon3} alt="" />
                                </div>
                                <h2>Blockchain Development</h2>
                            </div>
                            <div className="services">
                                <ul>
                                    <li>Issuing tokens & Coin</li>
                                    <li>Developing Blockchain protocol</li>
                                    <li>Developing Smart contracts</li>
                                    <li>Web3 application</li>
                                    <li>Dapps</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WhatWeDo;
