import businessPeopleImg from '../../../images/business-people.png';
// import underline from '../../../images/small-title-shape.svg';

const OurStory = () => {
    return (
        <div className="story-section section-margin">
            <div className="container story-area">
                <div className="story-left">
                    <img src={businessPeopleImg} alt="image" />
                </div>
                <div className="story-right">
                    <h2 className="title">
                        Our Story
                        {/* <span>
                            <img src={underline} alt="Underline" />
                        </span> */}
                    </h2>
                    <p className="story-content">
                        We are full time entrepreneurs and students at the same time. We started
                        Softcent from Startup Wise Guys' "2021 Young Entrepreneurs Summer Program".
                        Our developers works from Tallinn and Dhaka. We are hungry, passionate and
                        motivated to provide the best service and quality assurance with reasonable
                        pricing.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OurStory;
