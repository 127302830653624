// import underline from '../../../images/small-title-shape.svg';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import './DevTechnology.css';

import dartLogo from '../../../images/stacks/dart.png';
import pythonLogo from '../../../images/stacks/java.png';
import jsLogo from '../../../images/stacks/js.png';
import phpLogo from '../../../images/stacks/php.png';
import javaLogo from '../../../images/stacks/python.png';
import tsLogo from '../../../images/stacks/typescript.png';

const DevTechnology = () => {
    return (
        <div className="section-margin">
            <div className="container">
                <h2 className="title dev-technology-title">
                    Programming Language
                    {/* <span>
                    <img src={underline} alt="Underline" />
                </span> */}
                </h2>
                <div className="stack_section">
                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                        }}
                        modules={[Navigation, Autoplay]}
                        className="dev-logo"
                        spaceBetween={30}
                        slidesPerView={3}
                        navigation={false}
                        autoplay
                    >
                        <SwiperSlide>
                            <img src={jsLogo} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={tsLogo} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={phpLogo} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={pythonLogo} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={javaLogo} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={dartLogo} alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default DevTechnology;
