import mockup1 from '../../../images/mockup1.png';
import mockup2 from '../../../images/mockup2.png';
import mockup3 from '../../../images/mockup3.png';
// import underline from '../../../images/small-title-shape.svg';

const OurProducts = () => {
    return (
        <div className="section-margin">
            <div className="products-area">
                <div className="container">
                    <div className="our-products-title">
                        <h2 className="title">
                            Portfolio
                            {/* <span>
                            <img src={underline} alt="Underline" />
                        </span> */}
                        </h2>
                        <div className="product-subtitle">
                            <p>
                                We believe that great design should not be out of reach, so our
                                services are less than half the price of a full-time designer.
                            </p>
                            <div>
                                <a
                                    target="_blank"
                                    href="https://www.revorium.com/"
                                    className="btnBlue"
                                    rel="noreferrer"
                                >
                                    See More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="product-one">
                        <div className="product">
                            <h3>SwipeXYZ</h3>
                            <p>
                                Decentralized social media platform under Polygon network that gives
                                incentives to all the stakeholders in the platform while
                                stakeholders are the owner of their personal data and contents.
                            </p>
                            <div>{/* <a className="viewBtn">View Product</a> */}</div>
                        </div>
                        <div className="product-img">
                            <img src={mockup1} alt="Image" />
                        </div>
                    </div>
                    <div className="product-two">
                        <div className="product-img">
                            <img src={mockup2} alt="Image" />
                        </div>
                        <div className="product">
                            <h3>Paoya</h3>
                            <p>
                                Paoya App has changed the way people in Bangladesh do shopping and
                                order food and buy merchandise from home or at a store through its
                                innovative solution by adding value to the users and the partners
                                simultaneously.
                            </p>
                            <div>{/* <a className="viewBtn">View Product</a> */}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="product-one">
                        <div className="product">
                            <h3>Revorium</h3>
                            <p>
                                Pay & Get paid from anywhere in the world instantly. Revorium is the
                                best payment solution app for Freelancers and remote workers in the
                                market right now.
                            </p>
                            <div>
                                <a
                                    target="_blank"
                                    href="https://www.revorium.com/"
                                    className="viewBtn"
                                    rel="noreferrer"
                                >
                                    View Product
                                </a>
                            </div>
                        </div>
                        <div className="product-img">
                            <img src={mockup3} alt="Image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurProducts;
