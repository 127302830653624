import { useState } from 'react';
import PageTitle from '../Shared/PageTitle/PageTitle';
import './CookiePolicy.css';

const CookiePolicy = () => {
    // Defined interface
    interface User {
        name: string;
        email: string;
        phone: string;
        message: string;
        budget: string;
    }

    // Defined state
    const [userData, setUserData] = useState<User>({
        name: '',
        email: '',
        phone: '',
        message: '',
        budget: '',
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Onchange event handler
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    // Form handler
    const formHandler = (e: any) => {
        e.preventDefault();
        passUserData(userData);
    };

    // Custom function
    const passUserData = async (data: User) => {
        try {
            setLoading(true);
            const response = await fetch(`https://softcent-website.herokuapp.com/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            setLoading(false);

            if (response.ok) {
                console.log(responseData.data);
                // Set Message
                setSuccessMessage('Your query has been submitted!');

                // Window scroll top
                window.scrollTo(0, 0);

                // Input field empty
                setUserData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    budget: '',
                });
            } else {
                console.log('Error');
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    /* Passed page-title data */
    const title: string = ``;
    const subtitle: string = '';

    return (
        <div id="contact">
            <div className="contact-section">
                <div className="container">
                    <PageTitle title={title} subtitle={subtitle} />
                    <div>
                        <h1>Cookie Policy</h1>

                        <p>
                            This website, https://softcent.eu/, uses cookies. By visiting this
                            website you consent to the use of essential cookies. Functional,
                            targeting (marketing), and performance (analytics) cookies are subject
                            to your consent.
                        </p>
                        <p>
                            Most browsers accept cookies automatically, but if you prefer, you can
                            alter the settings of your browser to erase cookies or prevent automatic
                            acceptance. Generally, you have the option to see what cookies you have
                            and delete them individually; block third-party cookies or cookies from
                            particular sites; accept all cookies; be notified when a cookie is
                            issued, or reject all cookies. Visit the ‘options’ or ‘preferences’ menu
                            on your browser to change settings, and follow the links you find there
                            for more browser-specific information.
                        </p>
                        <p>
                            If you reject the use of cookies, you will still be able to visit our
                            website but you may be unable to use some services and features. By
                            using our website without deleting or rejecting some or all cookies, you
                            agree that we can place those cookies that you have not deleted or
                            rejected on your device.
                        </p>
                        <p>
                            More information about how to manage cookie preferences on the most
                            popular browsers can be found here:
                        </p>
                        <ul>
                            <li>Chrome</li>
                            <li>Firefox</li>
                            <li>Internet Explorer</li>
                            <li>Safari</li>
                            <li>Opera</li>
                        </ul>
                        <h2>What are cookies?</h2>
                        <p>
                            Cookies are small text files that are generated and modified by the
                            server, stored by the browser, and transmitted between the browser and
                            server at each interaction. Cookies are stored on your computer or
                            mobile device when you visit a website.
                        </p>
                        <p>
                            We use cookies to ensure that this website works properly, to learn how
                            you interact with the content in order to enhance your internet
                            experience, and to make our website work even more efficiently and
                            smoothly. We also use cookies to provide the most relevant products and
                            services to our site visitors.
                        </p>

                        <h2>More information</h2>
                        <p>
                            Softcent takes your privacy seriously. Please read our Privacy Policy
                            for more information about your data subject’s rights and how we process
                            personal data. You are always welcome to contact us at
                            howlader.sabuj@softcent.eu
                        </p>

                        <h2>Refund Policy:</h2>
                        <p>
                            Once you buy a package whether it’s monthly or yearly or a custom
                            contract with us for the development of your products or services if our
                            team could not provide you with services based on your requirements then
                            we will refund your payment within the next 7 working days. Simply
                            cancel the contract and money will be in your account within the next 7
                            working days after canceling the contract. If you have any questions or
                            want to know more about the refund policy then please contact our
                            support at howlader.sabuj@softcent.eu
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiePolicy;
