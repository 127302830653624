import { NavHashLink } from 'react-router-hash-link';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import portfolio1 from '../../../images/portfolio1.png';
import portfolio2 from '../../../images/portfolio2.png';
import portfolio3 from '../../../images/portfolio3.png';
import portfolio4 from '../../../images/portfolio4.png';
// import underline from '../../../images/small-title-shape.svg';

const Portfolio = () => {
    return (
        <div className="section-margin">
            <div className="container">
                {/* <div className="our-portfolio-title">
                <h2 className="title">Our Portfolio</h2>
            </div> */}
                <Swiper
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                    }}
                    modules={[Navigation, Autoplay]}
                    className="portfolio"
                    spaceBetween={30}
                    slidesPerView={3}
                    navigation={false}
                    autoplay
                >
                    <SwiperSlide>
                        <img src={portfolio1} alt="" />
                        <h4>Color System Design</h4>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={portfolio2} alt="" />
                        <h4>Logo Design</h4>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={portfolio3} alt="" />
                        <h4>UI/UX Design</h4>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={portfolio4} alt="" />
                        <h4>New One</h4>
                    </SwiperSlide>
                </Swiper>

                <p className="see-all-portfolio">
                    <NavHashLink smooth to="/#portfolio" className="btnBlue">
                        See All Protfolio
                    </NavHashLink>
                </p>
            </div>
        </div>
    );
};

export default Portfolio;
