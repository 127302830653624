import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import './Frameworks.css';
// import underline from '../../../images/small-title-shape.svg';

import flutterLogo from '../../../images/stacks/flutter.png';
import laravelLogo from '../../../images/stacks/laravel.png';
import reactLogo from '../../../images/stacks/react.png';
import sprintLogo from '../../../images/stacks/springboot1.png';
import vuejsLogo from '../../../images/stacks/vuejs.png';

const DesignTechnology = () => {
    return (
        <div className="section-margin">
            <div className="container stack_section">
                <h2 className="title design-technology-title">
                    Frameworks
                    {/* <span>
                    <img src={underline} alt="Underline" />
                </span> */}
                </h2>
                <Swiper
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                    }}
                    modules={[Navigation, Autoplay]}
                    className="tech-logo"
                    spaceBetween={30}
                    slidesPerView={3}
                    navigation={false}
                    autoplay
                >
                    <SwiperSlide>
                        <img src={reactLogo} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={vuejsLogo} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={laravelLogo} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={sprintLogo} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={flutterLogo} alt="" />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
};

export default DesignTechnology;
