import { useState } from 'react';
import PageTitle from '../Shared/PageTitle/PageTitle';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    // Defined interface
    interface User {
        name: string;
        email: string;
        phone: string;
        message: string;
        budget: string;
    }

    // Defined state
    const [userData, setUserData] = useState<User>({
        name: '',
        email: '',
        phone: '',
        message: '',
        budget: '',
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Onchange event handler
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    // Form handler
    const formHandler = (e: any) => {
        e.preventDefault();
        passUserData(userData);
    };

    // Custom function
    const passUserData = async (data: User) => {
        try {
            setLoading(true);
            const response = await fetch(`https://softcent-website.herokuapp.com/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            setLoading(false);

            if (response.ok) {
                console.log(responseData.data);
                // Set Message
                setSuccessMessage('Your query has been submitted!');

                // Window scroll top
                window.scrollTo(0, 0);

                // Input field empty
                setUserData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    budget: '',
                });
            } else {
                console.log('Error');
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    /* Passed page-title data */
    const title: string = ``;
    const subtitle: string = '';

    return (
        <div id="contact">
            <div className="contact-section">
                <div className="container">
                    <PageTitle title={title} subtitle={subtitle} />
                    <div>
                        <h1>Privacy Policy</h1>

                        <p>
                            Softcent is an information technology company with aimed services at in
                            software development, design & creativity, and sales & marketing for
                            small, medium, and large businesses.{' '}
                        </p>

                        <p>
                            This privacy notice describes what personal data is processed by{' '}
                            <b>Softcent OÜ</b> and its affiliates (hereinafter Softcent) when you
                            visit Softcents website at https://softcent.eu/.
                        </p>
                        <h2>Softcent’s information and contact details:</h2>

                        <p>Softcent OÜ </p>
                        <p> Estonian registry code: 16356905 </p>
                        <p>Address: Karu 17, 10120 Tallinn, Estonia</p>
                        <p>Email: howlader.sabuj@softcent.eu</p>
                        <p>Telephone: +372 57836500 </p>

                        <p>
                            This privacy policy describes the information we collect, what we do
                            with it, who we share it with, how long we store it, and what rights and
                            obligations we have.
                        </p>
                        <h2>Information that we collect about you</h2>
                        <p>
                            When you visit our website, https://Softcent.com, the following
                            information may be collected: your IP address, which browser version you
                            use, the time of your visit, how you use our website (e.g. pages you
                            have viewed), and information about your device (e.g. geographical
                            location, operating system, host name of accessing computer). For
                            details, please read our Cookie Policy.
                        </p>
                        <p>
                            The above-mentioned data does not contain additional personal data,
                            except when such information has been supplied by you, and cannot be
                            assigned to specific persons. A merge of this data with other data
                            sources will not be done, except in the event of your registration. We
                            reserve the right to check this data retrospectively if we become aware
                            of specific indications of illegal use.
                        </p>
                        <p>
                            You may choose to voluntarily submit your personal data by filling in
                            the form(s) on our website. Such information includes your email address
                            and your name, OU well OU your company-related information. Once you are
                            opt-in by providing your name, email address, or other information, your
                            IP address and the history associated with it are matched with the new
                            information to create a profile.
                        </p>
                        <p>
                            If we do want to collect other personal data through our website, we
                            will inform you beforehand and will explain what we intend to do with
                            your personal data.
                        </p>
                        <h2>The purposes and legal basis for the processing</h2>
                        <p>
                            We will process your personal information for the purposes listed in
                            this section given our legitimate interest in undertaking marketing
                            activities to offer you products or services that may be of your
                            interest and enhance those of our services that you may use or be using.
                        </p>
                        <h2>In particular, your data is processed for the following purposes:</h2>
                        <ul>
                            <li>
                                to facilitate, personalize, measure, and improve our advertising and
                                marketing efforts;{' '}
                            </li>
                            <li>
                                to personalize your experience on the sites by presenting products
                                and offers tailored to you;
                            </li>
                            <li>
                                to respond to your inquiries and fulfill your requests, such as
                                sending you requested materials and newsletters or other related
                                information and materials regarding our services;
                            </li>
                            <li>
                                to contact you to provide more information, by email, on topics you
                                are interested in;
                            </li>
                            <li>
                                to contact you by phone if you have provided your phone number, you
                                have shown great interest in our content and services and if we
                                believe that our services could create value for your business;
                            </li>
                            <li>
                                to organize webinars and other customer events you have registered
                                to participate in.
                            </li>
                        </ul>
                        <p>
                            You can opt out of receiving marketing communications from us by
                            following the unsubscribe instructions included in our marketing
                            communications.
                        </p>
                        <p>
                            Softcent’s legal basis for data processing is our legitimate interest in
                            a commercial enterprise (i.e. improving Softcent’s website and user
                            experience, as well as providing marketing and advertising). Further,
                            the processing of personal data is, as applicable, based on your
                            consent, as described in greater detail in our Cookie Policy.
                        </p>
                        <h2>Recipients of your personal data</h2>
                        <p>
                            Within Softcent, only authorized personnel of Softcent to have access to
                            your personal data in case of necessity for operating this website,
                            answering your requests, and providing you with advertising material or
                            customer service.
                        </p>
                        <p>
                            We may also share your personal information with third-party service
                            providers according to this Privacy Notice. When necessary for technical
                            support and maintenance, employees of ActiveCampaign might have limited
                            access to your data.
                        </p>
                        <p>
                            Third-party service providers have access to personal information only
                            as needed to perform their functions. We maintain processes designed to
                            ensure that any processing of personal data by third-party service
                            providers is consistent with this Privacy Notice and protects the
                            confidentiality, availability, and integrity of your personal data.
                            Where required by law, we put in place legal mechanisms designed to
                            ensure adequate protection of your personal data.
                        </p>
                        <h2>Transfers of your personal data to third countries</h2>
                        <p>
                            Following the submission of your data, the information you have sent to
                            us can be made available to Softcent’s Marketing and Sales teams
                            worldwide (including certain third countries) for the purposes described
                            above. A list of the partners of Softcent OU in third countries that may
                            have access to and may process your personal data in accordance with
                            this Privacy Notice for Website visitors.
                        </p>
                        <h2>The storage period</h2>
                        <p>
                            We retain the identifiable data we collect for 12 months from your last
                            visit to our website.
                        </p>
                        <p>
                            You can nevertheless always ask Softcent to delete your data collected
                            for marketing purposes by sending a request via e-mail to
                            howlader.sabuj@softcent.eu{' '}
                        </p>
                        <h2>Your rights as a data subject</h2>
                        <p>
                            You have the following rights under the GDPR, subject to the applicable
                            statutory limitations:
                        </p>

                        <p>You have the right to:</p>
                        <ul>
                            <li>request access to information that Softcent has about you;</li>
                            <li>
                                request the correction of information that Softcent has about you;
                            </li>
                            <li>request the erasure of information that Softcent has about you;</li>
                            <li>restrict the use of your personal data;</li>
                            <li>receive your personal data;</li>
                            <li>object to the processing of your personal data;</li>
                            <li>withdraw your consent for the use of your personal data;</li>
                            <li>
                                file a complaint about our processing with a supervisory authority.
                            </li>
                        </ul>
                        <p>
                            GDPR also gives you the right to lodge a complaint with a supervisory
                            authority in the EU/EEA state where you work, normally live, or where
                            any alleged infringement of data protection laws may have occurred.
                        </p>
                        <p>
                            Please contact us using the details above if you have any questions
                            about Softcent’s Privacy Policy or its processing of your personal data.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
