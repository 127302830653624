import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home/Home';
import Footer from './components/Shared/Footer/Footer';
import Header from './components/Shared/Header/Header';
import CookiePolicy from './components/TermsPolicy/CookiePolicy';
import PrivacyPolicy from './components/TermsPolicy/PrivacyPolicy';
import RefundPolicy from './components/TermsPolicy/RefundPolicy';
import Work from './components/Work/Work';

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/work" element={<Work />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
                    <Route path="/refund-policy" element={<RefundPolicy />} />
                </Routes>

                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
