import workImg1 from '../../../images/work1.png';
import workImg2 from '../../../images/work2.png';
import workImg3 from '../../../images/work3.png';
import workImg4 from '../../../images/work4.png';
import workImg5 from '../../../images/work5.png';
import workImg6 from '../../../images/work6.png';
import PageTitle from '../../Shared/PageTitle/PageTitle';

const WorkTop = () => {
    const title: string = 'Our Works';
    const subtitle: string =
        'We believe that great design should not be out of reach, so our services are less than half the price of a full-time designer.';

    return (
        <div className="container section-margin">
            <PageTitle title={title} subtitle={subtitle} />
            <div className="our-works-section">
                <div className="work">
                    <div>
                        <img src={workImg1} alt="softcent" />
                    </div>
                    <h3>Color System Design</h3>
                </div>
                <div className="work">
                    <div>
                        <img src={workImg2} alt="softcent" />
                    </div>
                    <h3>Logo Design</h3>
                </div>
                <div className="work">
                    <div>
                        <img src={workImg3} alt="softcent" />
                    </div>
                    <h3>UI/UX Design</h3>
                </div>
                <div className="work">
                    <div>
                        <img src={workImg4} alt="softcent" />
                    </div>
                    <h3>Application Design</h3>
                </div>
                <div className="work">
                    <div>
                        <img src={workImg5} alt="softcent" />
                    </div>
                    <h3>Application Design</h3>
                </div>
                <div className="work">
                    <div>
                        <img src={workImg6} alt="softcent" />
                    </div>
                    <h3>Web Design</h3>
                </div>
            </div>
        </div>
    );
};

export default WorkTop;
